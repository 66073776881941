import {ResizeObserver as Polyfill} from '@juggle/resize-observer'
import MenuSpy from 'menuspy'

const ResizeObserver = window.ResizeObserver || Polyfill

var $returnValue = true
const ro = new ResizeObserver((entries, observer) => {
    entries.forEach((entry) => {
        if (entry.contentRect.width < 1024) {
           $returnValue = false
        }
        else if (entry.contentRect.width >= 1024) {
           $returnValue = true
        }
    })
  })
  ro.observe(document.body); // Watch dimension changes on body

document.addEventListener("DOMContentLoaded", function() {
    var subMenuItems = document.querySelectorAll('.sub-menu .menu-item'),
        menuHasChildren =  document.querySelectorAll('.menu-item-has-children'),
        headerTop = document.getElementById('header-top'),
        header = document.querySelector('header')

    
    // // Sticky Header
    // if (typeof(headerTop) != 'undefined' && headerTop != null) {
    //     var observer = new IntersectionObserver(function(entries) {
    //         // no intersection with screen
    //         if(entries[0].intersectionRatio === 0) {
    //             header.classList.add("is-stuck");
    //         }
    //         // fully intersects with screen
    //         else if(entries[0].intersectionRatio === 1) {
    //             header.classList.remove("is-stuck");
    //         }
    //     }, { threshold: [0,1]});
        
    //     observer.observe(document.querySelector("#header-top"));
    // }

    subMenuItems.forEach((obj, index) => {
        obj.querySelector('a').classList.add('sub-anchor')
    })

    menuHasChildren.forEach((obj, index) => {
        obj.querySelector('a').addEventListener('click', (e) => {
            event.stopPropagation()
            event.returnValue = $returnValue
            obj.classList.toggle('menu-is-open')
        }, true)
    }) 


    // Mac Veneer font spacing hack
    if (navigator.userAgent.match(/Macintosh/) || navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
        document.querySelectorAll('.heading-large, .btn-default, .wpcf7-submit').forEach((obj, index) => {
            obj.classList.add('is-mac')
        })
    }


    // Sangria 101 Menu
    var menu = document.querySelector('.navigation');
    var ms = new MenuSpy(menu,{

        // menu selector
        menuItemSelector: 'a[href^="#"]',
    
        // CSS class for active item
        activeClass   : 'menu-active',
    
        // amount of space between your menu and the next section to be activated.
        threshold     : 150,
    
        // enable or disable browser's hash location change.
        enableLocationHash: true,
    
        // timeout to apply browser's hash location.
        hashTimeout   : 300,
    
        // called every time a new menu item activates.
        callback      : null
        
    });

    var menuTop = document.querySelector('.navigation-top');
    const observer = new IntersectionObserver( 
    ([e]) => menu.classList.toggle('isSticky', e.intersectionRatio < 1),
    {threshold: [1]}
    );

    observer.observe(menuTop)

    })