import {Scene} from 'scrollmagic'
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min'
import globals from './globals'


document.addEventListener("DOMContentLoaded", function() {
    var fruitFull = document.querySelectorAll('.animation-top svg'),
        animeText = document.querySelectorAll('.anim-text')
    
    var fruitAnimation = new TimelineMax()
    .fromTo(fruitFull, .4, {scale: 0.5}, {scale: 1, ease: Power0.ease})

    var fruitAnimationScene = new Scene({
        triggerElement: fruitFull,
        triggerHook: 1,
        offset: 0,
        duration: '0',
    })
    .setTween(fruitAnimation)
    .addTo(globals.controller)



    animeText.forEach((obj, index) => {
        var textAnimation = new TimelineMax()
        .fromTo(obj, 1, {
            yPercent: 30,
            opacity: 0

        }, {
            opacity: 0.99,
            yPercent: 0,
        }, '+=0')
        // .to('#video-side-content-' + index + ' .content-container', 1, {
        //     yPercent: -40,
        //     autoAlpha: 0.1
        // })

        // create scene to pin and link animation
        var scene = new Scene({
                triggerElement: obj,
                triggerHook: 1,
                offset: 0,
                duration: '0',
            })
            .setTween(textAnimation)
            .addTo(globals.controller)
    })
})