document.addEventListener("DOMContentLoaded", function() {
    var sizes = document.querySelectorAll('.size')

    sizes.forEach((obj, index) => {
        obj.addEventListener('click', (e) => {
            e.preventDefault()
            var $index = e.currentTarget.dataset.index,
                productImage = document.querySelector('.product-image-' + $index),
                serving = document.querySelector('.serving-' + $index)

                new Promise((resolve, reject) => {
                    removeAllActive()
                    resolve()
                })
                .then(() => {
                    obj.classList.add('size-active')
                    productImage.classList.add('size-active', 'fadeInScale')
                    serving.classList.add('size-active', 'fadeInScale')
                    productImage.classList.remove('size-hide')
                    serving.classList.remove('size-hide')
                })
                .catch((error) => {
                    console.log('error')
                })
                
        })
    })

    function removeAllActive() {
        var productElements = document.querySelectorAll('.product-images img, .size, .serving')

        productElements.forEach((obj, index) => {
            obj.classList.remove('size-active')
            obj.classList.add('size-hide')

            if (obj.classList.contains('fadeInScale')) obj.classList.remove('fadeInScale')
        })
    }
})