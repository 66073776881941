document.addEventListener("DOMContentLoaded", function() {
    var catLinks = document.querySelectorAll('.archive-cocktails .navigation-menu li'),
        categories = document.querySelectorAll('.cat')

    catLinks.forEach((obj, index) => {
        var catIndex = obj.querySelector('a').dataset.index,
            activeCat = document.querySelector('.cat-' + catIndex);
        
        obj.querySelector('a').addEventListener('click', (e) => {
            e.preventDefault();

            new Promise((resolve, reject) => {
                removeAllActiveCat()
                resolve()
            })
            .then(() => {
                obj.classList.add('cat-active')
                activeCat.classList.add('cat-active', 'fadeInScale')
            })
            .catch((e) => {
                console.log(e)
            })
        })
    })

    function removeAllActiveCat() {
        catLinks.forEach((obj, index) => {
            if (obj.classList.contains('cat-active')) obj.classList.remove('cat-active')
        })
        categories.forEach((obj, index) => {
            if (obj.classList.contains('cat-active')) obj.classList.remove('cat-active', 'fadeInScale')
        })
    }
})